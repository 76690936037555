import type { Slider as SliderType } from "__generated__/sanity/sanitySchema";
import { Flex } from "@daangn/carotene";
import {
  IconChevronLeftLine,
  IconChevronRightLine,
} from "@daangn/react-monochrome-icon";
import useEmblaCarousel from "embla-carousel-react";

import { useDotButton } from "~/hooks/useDotButton";
import { usePrevNextButtons } from "~/hooks/usePrevNextButtons";

import * as css from "./HeroCarousel.css";
import SanityImage from "./SanityImage";

export type HeroCarouselProps = {
  data: SliderType | null;
};

const HeroCarousel: React.FC<HeroCarouselProps> = (props) => {
  const slides = props.data?.SliderSlide ?? [];

  const [emblaRef, emblaApi] = useEmblaCarousel({
    skipSnaps: true,
    active: slides.length > 1,
  });

  const {
    selectedIndex: currentIndex,
    scrollSnaps,
    onDotButtonClick,
  } = useDotButton(emblaApi);

  const {
    prevBtnDisabled,
    nextBtnDisabled,
    onPrevButtonClick,
    onNextButtonClick,
  } = usePrevNextButtons(emblaApi);

  const handlePrevButtonClick = () => {
    if (prevBtnDisabled) {
      onDotButtonClick(slides.length - 1);
      return;
    }
    onPrevButtonClick();
  };

  const handleNextButtonClick = () => {
    if (nextBtnDisabled) {
      onDotButtonClick(0);
      return;
    }
    onNextButtonClick();
  };

  if (slides.length === 0) return null;

  const currentItem = slides[currentIndex];

  return (
    <div
      className={css.container}
      style={{
        ...(!currentItem?.SliderIsFullSizeImage &&
        currentItem?.SliderBackgroundColor
          ? {
              backgroundColor: currentItem.SliderBackgroundColor,
            }
          : null),
      }}
    >
      <div
        className={css.centered}
        style={{
          ...(currentItem?.SliderIsFullSizeImage &&
          currentItem?.SliderBackgroundColor
            ? {
                backgroundColor: currentItem.SliderBackgroundColor,
              }
            : null),
        }}
      >
        {slides.map((item, i) => {
          const commonStyleOptions = {
            position:
              i < currentIndex
                ? ("before" as const)
                : i === currentIndex
                ? ("active" as const)
                : ("after" as const),
            isFullSize: item?.SliderIsFullSizeImage ?? false,
          };

          const sliderImage = {
            base: item?.SliderImages?.BaseImage?.asset?.url ?? "",
            small: item?.SliderImages?.SmallImage?.asset?.url ?? "",
            medium: item?.SliderImages?.MediumImage?.asset?.url ?? "",
            large: item?.SliderImages?.LargeImage?.asset?.url ?? "",
          };

          return (
            <span key={i}>
              <SanityImage
                src={sliderImage.base}
                alt="1"
                className={css.image({
                  ...commonStyleOptions,
                  size: "base",
                })}
              />
              <SanityImage
                src={sliderImage.small}
                alt="1"
                className={css.image({
                  ...commonStyleOptions,
                  size: "small",
                })}
              />
              <SanityImage
                src={sliderImage.medium}
                alt="1"
                className={css.image({
                  ...commonStyleOptions,
                  size: "medium",
                })}
              />
              <SanityImage
                src={sliderImage.large}
                alt="1"
                className={css.image({
                  ...commonStyleOptions,
                  size: "large",
                })}
              />
            </span>
          );
        })}
        <div className={css.slider} ref={emblaRef}>
          <Flex width="full">
            {slides.map((item, i) => {
              const isFirstCarouselItem = i === 0;
              const titlesColor =
                item?.SliderTitlesColor === "black" ||
                item?.SliderTitlesColor === "white"
                  ? item.SliderTitlesColor
                  : "black";

              return (
                <div
                  className={css.slide({
                    visible: currentIndex === i,
                  })}
                  key={i}
                >
                  {isFirstCarouselItem ? (
                    <>
                      <h1
                        className={css.title({
                          titlesColor,
                        })}
                      >
                        {item?.SliderTitle}
                      </h1>
                      <h2
                        className={css.subTitle({
                          titlesColor,
                        })}
                      >
                        {item?.SliderSubTitle}
                      </h2>
                    </>
                  ) : (
                    <>
                      <h3
                        className={css.title({
                          titlesColor,
                        })}
                      >
                        {item?.SliderTitle}
                      </h3>
                      <h4
                        className={css.subTitle({
                          titlesColor,
                        })}
                      >
                        {item?.SliderSubTitle}
                      </h4>
                    </>
                  )}
                </div>
              );
            })}
          </Flex>
        </div>

        <button
          aria-label="Prev banner image"
          className={css.arrow({ position: "left" })}
          onClick={handlePrevButtonClick}
        >
          <IconChevronLeftLine
            className={css.arrowIcon({
              titlesColor:
                currentItem?.SliderTitlesColor === "white" ? "white" : "black",
            })}
          />
        </button>
        <button
          aria-label="Next banner image"
          className={css.arrow({ position: "right" })}
          onClick={handleNextButtonClick}
        >
          <IconChevronRightLine
            className={css.arrowIcon({
              titlesColor:
                currentItem?.SliderTitlesColor === "white" ? "white" : "black",
            })}
          />
        </button>

        <Flex as="ol" gap={2} className={css.dotContainer}>
          {scrollSnaps.map((_, i) => (
            <li key={i}>
              <button
                aria-label={`banner image ${i + 1}`}
                onClick={() => onDotButtonClick(i)}
                className={css.dot({ active: i === currentIndex })}
              />
            </li>
          ))}
        </Flex>
      </div>
    </div>
  );
};

export default HeroCarousel;
