import { IconChevronRightFill } from "@daangn/react-monochrome-icon";
import React from "react";

import * as css from "./MoreCardButton.css";

type MoreCardButtonProps = {
  label: string;
  theme?: "base" | "homePage";
};

export default function MoreCardButton({
  label,
  theme = "base",
}: MoreCardButtonProps) {
  return (
    <div className={css.container({ theme })}>
      <div className={css.textWrapper}>
        <span className={css.label}>{label}</span>
        <span className={css.iconWrapper}>
          <IconChevronRightFill size={14} />
        </span>
      </div>
    </div>
  );
}
